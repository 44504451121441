.rrule-generator-scope {
  margin-top: 1.5rem;

  @import "~bootstrap/scss/bootstrap.scss";
  @import "~react-rrule-generator/build/styles.css";

  .offset-sm-2 {
    margin-left: 0 !important;
    /*padding-left: 0!important;*/
  }

  .offset-sm-2 input {
    margin-left: 12px;
  }

  .text-sm-right {
    text-align: left !important;
    width: 16.6% !important;
    max-width: 16.6% !important;
  }

  .col-sm-2 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .form-group {
    align-items: center;
  }

  .btn {
    border-radius: 50% !important;
    flex-flow: row wrap !important;
    height: 50px !important;
    margin: 0 8px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-primary {
    background: transparent !important;
    border-color: transparent !important;
    color: black;
    //box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%) !important;
  }

  .btn-primary:hover {
    color: black;
  }

  .active {
    background: #3e535a !important;
  }

  .px-0 {
    border: none !important;
  }

  a:hover {
    color: inherit;
    text-decoration: none;
  }

  & .form-group select {
    background-color: white;
    border-radius: 100px;
    -webkit-appearance: none;
    margin-left: 10px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.fc-col-header-cell-cushion {
  color: #000;
}

.printer-friendly-appointments {
  display: none;
}

@media print {
  html {
    font-size: 80%;
  }

  .workoutplan__image {
    display: none;
  }
  .workoutplan__table {
    flex-basis: 100% !important;
    max-width: 100% !important;
    font-size: 80% !important;
  }
  .printer-friendly-appointments {
    display: block;
    padding: 32px;
    tr {
      background: white;
      border-color: #eee !important;
    }
    th,
    td {
      border-color: #eee !important;
      color: black !important;
    }
  }
}
